import { useEffect, useState } from 'react';

function Home() {
  const [iframeHeight, setIframeHeight] = useState('90vh');

  useEffect(() => {
    function handleResize() {
      // Get the viewport height and calculate the iframe height minus the footer height
      const vh = window.innerHeight * 0.01;
      const footerHeight = document.getElementById('footer').offsetHeight;
      setIframeHeight(`calc(${vh * 100}px - ${footerHeight}px)`);
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the function initially to set the height based on the current viewport height

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App" style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <header className="App-header">
        <iframe 
          src="https://deepinnovators-application.web.app" 
          style={{width: '100%', height: iframeHeight, border: 'none'}}
          title="Deep Innovators"
        ></iframe>
      </header>
      <footer id="footer" style={{ display: 'flex', justifyContent: 'center', padding: '5px', position: 'fixed', bottom: '0', width: '100%', backgroundColor: 'white' }}>
        contact@deepinnovators.com&emsp;&emsp;www.deepinnovators.com/privacy
      </footer>
    </div>
  );
}

export default Home;


