import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Home';
import Onboarding from './Onboarding';

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/onboarding" element={<Onboarding/>} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
