import { useEffect, useState } from 'react';

function Onboarding() {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');

  const [iframeHeight, setIframeHeight] = useState('90vh');

  useEffect(() => {
    function handleResize() {
      // Get the viewport height and calculate the iframe height minus the footer height
      const vh = window.innerHeight * 0.01;
      setIframeHeight(`calc(${vh * 100}px`);
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the function initially to set the height based on the current viewport height

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <iframe
        src={`https://deepinnovators-application.web.app/onboarding?email=${email}`}
        style={{ width: '100%', height: iframeHeight, border: 'none' }}
        title="Onboarding"
      ></iframe>
    </div>
  );
}

export default Onboarding;
